import Api from '@/services/Index';

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/approval/step/update/status/' + id, formData);
}

const restore = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/approval/step/restore/status/' + id);
}

export default {
    update,
    restore
}
